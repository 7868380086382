import React, { useState } from "react";
import axios from "axios";

import "../styles/centered-box.less";
import "../styles/offres.less";

import { getLocalFormValues, navigate } from "../utils";

import { Layout, Row, Col, Card, Button, message } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";
import ShowOffer from "../components/ShowOffer.js";

const { Content } = Layout;

function chooseOffer(newFormValues, setIsLoading) {
  const { requestId } = newFormValues;
  setIsLoading(true);
  localStorage.setItem(requestId, JSON.stringify(newFormValues));
  axios
    .post(process.env.GATSBY_API_URL + "/update-registration", newFormValues)
    .then(() => {
      navigate(`/recapitulatif#${requestId}`);
    })
    .catch((e) => {
      message.error("Erreur lors de la communication : " + e.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
}

function round2decimals(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export default function ChooseAnOffer() {
  const [isLoading, setIsLoading] = useState(false);
  const initFormValues = getLocalFormValues();

  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    navigate("/");
    return null;
  }
  const { firstname, vehicle_price } = initFormValues;
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h1>{firstname}, roulez serein !</h1>
            <h2>Deux packs, zero surprise</h2>
            <Row gutter={16}>
              <Col className="offres" xs={24} lg={12}>
                <Card
                  title={
                    <div>
                      <h2>Droit de rouler</h2>
                      <p className="price">4,99€&nbsp;/&nbsp;mois</p>
                    </div>
                  }
                >
                  <Button
                    onClick={() =>
                      chooseOffer(
                        {
                          ...initFormValues,
                          offer: "droit_de_rouler",
                        },
                        setIsLoading
                      )
                    }
                    className="btn-selectionner"
                    type="primary"
                    size="large"
                    loading={isLoading}
                  >
                    Je m'assure
                  </Button>
                  <ShowOffer offer="droit_de_rouler" />
                </Card>
              </Col>

              <Col className="offres" xs={24} lg={12}>
                <Card
                  title={
                    <div>
                      <h2>Esprit tranquille</h2>
                      <p className="price">
                        <p className="price">
                          {round2decimals(15.77 + 0.0075 * vehicle_price)}
                          €&nbsp;/&nbsp;mois
                        </p>
                      </p>
                    </div>
                  }
                >
                  <Button
                    onClick={() =>
                      chooseOffer(
                        {
                          ...initFormValues,
                          offer: "esprit_tranquille",
                        },
                        setIsLoading
                      )
                    }
                    className="btn-selectionner"
                    type="primary"
                    size="large"
                    loading={isLoading}
                  >
                    Je m'assure
                  </Button>
                  <ShowOffer offer="esprit_tranquille" />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
